/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix} from "gatsby"

import Header from "./header"
import "./layout.css"
import Helmet from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      > */}
        <main>{children}</main>
        <Helmet>
        <script src={withPrefix('script.js')} type="text/javascript" />
        </Helmet>
           <footer class="third-bg">
          <div class="footer_bottom fourth-bg">
            {/* <!-- Keep Footer Credit Links Intact --> */}
            <p>
              {" "}
              {new Date().getFullYear()} &copy; Copyright AGiraffeAndAHalf. All rights Reserved.{" "}
            </p>
            <a href="#" class="backtop">
              {" "}
              ^{" "}
            </a>
          </div>
        </footer>
      {/* </div> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
